import React from "react";
import { useNavigate } from "react-router-dom";

function WhatIBelieveIn() {
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[800px] px-12 mx-auto my-0 my-12 tablet:my-36"
      id="container"
    >
      <button className="link mb-4" onClick={() => navigate(-1)}>
        Back
      </button>
      <div className="flex flex-col gap-4 items-start mb-16">
        <div className="text-left w-full font-eb-garamond gap-2 flex flex-col">
          <h1 className="text-black-primary font-eb-garamond text-4xl">
            what i believe in
          </h1>
          <ul className="list-disc pl-5">
            <li>
              take bets sequentially. fail/succeed one thing at a time. you will
              learn more.
            </li>
            <li>
              you cannot force yourself to be focused; it is earned, not found.
            </li>
            <li>
              always try to turn{" "}
              <a
                href="https://klr.tumblr.com/post/154784481858/messy-thought-neat-thought"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                messy thought into neat thought
              </a>
              . reminds me of the{" "}
              <a
                href="https://www.swyx.io/simplicity-rush"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                midwit curve
              </a>
              .
            </li>
            <li>
              a moment exists in two states: work vs play; editing vs writing;
              converge vs diverge; focus vs exploration.
            </li>
            <li>people should be more forgiving.</li>
          </ul>
        </div>

        <div className="text-left w-full font-eb-garamond gap-2 flex flex-col">
          <h2 className="text-black-primary font-eb-garamond text-4xl">
            a stream
          </h2>
          <ul className="list-disc pl-5">
            <li>
              "surprise" is an underutilized, but high potential, emotion in
              helping someone learn. could it be helpful to show learners high
              entropy tokens in their work to reveal what surprised the AI?
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default WhatIBelieveIn;
